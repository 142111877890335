<template>
  <mdb-container>
    <Publicheader></Publicheader>
    <mdb-row class="mt-4" v-if="errormsg">
      <mdb-col>
        <mdb-alert color="danger">
          {{errormsg}}
        </mdb-alert>
      </mdb-col>
    </mdb-row>
    <div v-if="!errormsg">
      <mdb-row>
        <mdb-col>
          <mdb-breadcrumb class="breadcrumb-with-arrows mt-2 white">
            <mdb-breadcrumb-item><a v-on:click="gotohome">Accueil</a></mdb-breadcrumb-item>
            <mdb-breadcrumb-item><a v-on:click="gotoallfuturwebinaires">Webinaires à venir</a></mdb-breadcrumb-item>
            <mdb-breadcrumb-item active>{{webinar.title}}</mdb-breadcrumb-item>
          </mdb-breadcrumb>
        </mdb-col>
      </mdb-row>
      <mdb-row class="" v-if="webinar">
        <mdb-col md="8">
          <mdb-row>
            <mdb-col class="">
              <mdb-view hover rounded class="z-depth-1-half mb-4">
                <img class="img-fluid" :src="webinar.coverimage" :alt="webinar.title"/>
              </mdb-view>
              <h4><strong>{{webinar.title}}</strong></h4>
              <h5 class="orange-text"><strong>{{webinar.eventdate}}</strong></h5>
              <h6 class="blue-text" v-if="webinar.presentedby">Présenté par: <strong>{{webinar.presentedby}}</strong></h6>
            </mdb-col>
          </mdb-row>
        </mdb-col>
        <mdb-col md="4" class="mb-3">
          <mdb-card>
            <mdb-card-header color="grey darken-3">S'inscrire à ce webinaire</mdb-card-header>
            <mdb-card-body v-if="!ispast || canshowform">
              <mdb-container>
                <mdb-row v-if="registererr">
                  <mdb-col md="12">
                    <mdb-alert color="danger">
                      {{registererr}}
                    </mdb-alert>
                  </mdb-col>
                </mdb-row>
                <mdb-row v-if="registrationcomplete">
                  <mdb-col md="12">
                    <mdb-alert color="success" v-html="successmsg"></mdb-alert>
                  </mdb-col>
                </mdb-row>
                <div v-else>
                  <mdb-row>
                    <mdb-col md="12">
                      <mdb-input label="Prénom" v-model="register.firstname" required />
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col md="12">
                      <mdb-input label="Nom de famille" v-model="register.lastname" required />
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col md="12">
                      <mdb-input label="Adresse courriel" v-model="register.email" required />
                    </mdb-col>
                  </mdb-row>
                  <div class="text-right">
                    <mdb-btn outline="elegant" v-if="isSaving===false" v-on:click="registertowebinar()">S'inscrire</mdb-btn>
                  </div>
                </div>
              </mdb-container>
            </mdb-card-body>
            <mdb-card-body v-else>
              <h5>Ce webinaire est déjà passé</h5>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col>
          <div v-html="computedDescription"></div>
        </mdb-col>
      </mdb-row>
    </div>
    <Publicfooter></Publicfooter>
  </mdb-container>
</template>

<script>
    import Vue from 'vue';
    import Publicheader from '@/components/Publicheader.vue';
    import Publicfooter from '@/components/Publicfooter.vue';

    import {
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbAlert,
        mdbView,
        mdbCard,
        mdbCardHeader,
        mdbCardBody,
        mdbInput,
        mdbBtn,
        mdbBreadcrumb,
        mdbBreadcrumbItem,
    } from 'mdbvue';

    import Apicall from '../libs/Apicall';

    export default Vue.extend({
        name: 'Registerwebinar',
        components: {
            Publicheader,
            Publicfooter,
            mdbContainer,
            mdbRow,
            mdbCol,
            mdbAlert,
            mdbView,
            mdbCard,
            mdbCardHeader,
            mdbCardBody,
            mdbInput,
            mdbBtn,
            mdbBreadcrumb,
            mdbBreadcrumbItem,
        },
        computed: {
            computedDescription() {
                // equivalent à nl2br
                return this.webinar.description.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
            },
        },
        data() {
            return {
                webinar: {
                    coverimage: '',
                    title: '',
                    eventdate: '',
                    presentedby: '',
                    description: '',
                    canonical: '',
                },
                errormsg: '',
                successmsg: '',
                ispast: false,
                canshowform: false,
                register: {
                    firstname: '',
                    lastname: '',
                    email: '',
                },
                registererr: '',
                registrationcomplete: false,
                isSaving: false,
                currentpermalink: '',
                agencycontext: null,
                agentcontext: null,
            };
        },
        mounted() {
            this.currentpermalink = this.$route.params.ident;
            this.agencycontext = this.$route.params.agency;
            this.agentcontext = this.$route.params.agent;
            this.loadAndDisplayThisWebinar();
        },
        methods: {
            gotoallfuturwebinaires() {
                this.$router.push({ name: 'Futurwebinaires' });
            },
            gotohome() {
                this.$router.push({ name: 'Home' });
            },
            loadAndDisplayThisWebinar() {
                const self = this;
                const api = new Apicall();

                window.localStorage.removeItem('agentcontext');
                window.localStorage.removeItem('agencycontext');

                api.loadWebinar(this.currentpermalink, { agent: this.agentcontext, agency: this.agencycontext }).then((response) => {
                    if (response.status === 'OK') {
                        if (response.data.redirect !== null) {
                            // on fait le redirect comme ça sinon ça ne reload pas le nouveau contexte agent/agence
                            window.location.href = response.data.redirect;
                        } else {
                            if (self.$store.state.agentcontext !== response.data.agentcontext && response.data.agentcontext) {
                                self.$store.state.agentcontext = response.data.agentcontext;
                                self.$store.state.agencycontext = '';
                            }
                            if (response.data.agentcontext) {
                                window.localStorage.setItem('agentcontext', response.data.agentcontext);
                            }
                            if (self.$store.state.agencycontext !== response.data.agencycontext && response.data.agencycontext) {
                                self.$store.state.agencycontext = response.data.agencycontext;
                                self.$store.state.agentcontext = '';
                            }
                            if (response.data.agencycontext) {
                                window.localStorage.setItem('agencycontext', response.data.agencycontext);
                            }
                        }
                        self.webinar = response.data.webinar;
                        self.ispast = response.data.eventpast;
                        self.canshowform = response.data.canshowform;
                    } else {
                        self.errormsg = response.msg;
                    }
                });
                if (localStorage.getItem('currentclient')) {
                  console.log(localStorage.getItem('currentclient'));
                  self.register = JSON.parse(localStorage.getItem('currentclient'));
                }
            },
            registertowebinar() {
                const self = this;
                const api = new Apicall();

                this.registererr = '';
                api.registertowebinar(this.register, this.currentpermalink, this.$store.state.geoinfos).then((response) => {
                    if (response.status === 'OK') {
                        self.registrationcomplete = true;
                        self.successmsg = response.data.msg;
                        window.localStorage.setItem('currentclient', JSON.stringify(self.register));
                    } else {
                        self.registererr = response.msg;
                    }
                });
            },
        },
    });
</script>
