<template>
  <mdb-container>
    <Adminheader></Adminheader>
    <section class="mt-3" v-if="!ispast">
      <mdb-row>
        <mdb-col xl="6" md="6" class="mb-4">
          <mdb-btn outline v-on:click="gotowebinarslist" color="primary">
            <mdb-icon icon="arrow-left" class="mr-1"/>
            Retour à la liste des webinaires
          </mdb-btn>
        </mdb-col>
      </mdb-row>
      <mdb-row v-if="errormsg">
        <mdb-col xl="12" md="12" class="mb-4">
          <mdb-alert color="danger">
            {{ errormsg }}
          </mdb-alert>
        </mdb-col>
      </mdb-row>
      <mdb-row v-if="!cantload">
        <mdb-col xl="12" md="12" class="mb-4">
          <mdb-card>
            <mdb-card-header color="orange accent-3">Modifier un Webinaire</mdb-card-header>
            <mdb-card-body>
              <mdb-container>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input class="" label="ID Youtube" v-model="webinaire.youtubeid"/>
                    <mdb-alert color="danger" v-if="youtubevalidateerrmsg">
                      {{ youtubevalidateerrmsg }}
                    </mdb-alert>
                    <mdb-btn outline="elegant" size="sm" v-on:click="validateYoutubeId">
                      Valider le ID Youtube
                      <span class="spinner-border spinner-border-sm ml-3" role="status"
                            aria-hidden="true" v-if="youtubevalidationisloading"></span>
                    </mdb-btn>
                    <hr/>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input label="Titre" v-model="webinaire.title" required/>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input label="Date / heure du webinaire" :value="handlePickersValue()"
                               @click.native="$refs.datePicker.open()" required/>
                    <mdb-date-picker-2
                      ref="datePicker"
                      disable-input
                      v-model="webinaire.thedate"
                      disabledPast
                      @close="opentimepicker"/>
                    <mdb-time-picker
                      ref="timePicker"
                      disable-input
                      v-model="webinaire.thehour"/>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input label="Présenté par" v-model="webinaire.presentedby"/>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input type="textarea" label="Mini description" :rows="2" maxlength="64"
                               counter :counterMaxValue="64" required v-model="webinaire.minidesc"/>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input type="textarea" label="Description complète" :rows="5" required
                               v-model="webinaire.fulldesc"/>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input type="textarea"
                               label="Contenu du courriel à envoyer après la présentation"
                               :rows="15" required v-model="webinaire.afterwebinaremailcontent"/>
                    <mdb-switch onLabel="Envoyer le courriel"
                                offLabel="Ne pas envoyer le courriel" v-model="webinaire.sendmail"/>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input type="textarea"
                               label="Contenu du courriel à envoyer après la présentation aux agents"
                               :rows="15" required
                               v-model="webinaire.afterwebinaragentemailcontent"/>
                    <p class="font-small blue-text d-flex justify-content-start">Le contenu de ce
                      champ sera ajouté au courriel récapitulatif envoyé après la présentation</p>
                    <hr/>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input type="textarea" label="Contenu du courriel à envoyer après la présentation aux non-participants" :rows="15" required v-model="webinaire.afterwebinarnonattendeeemailcontent" />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-alert color="info">
                      Pour des résultats optimaux, la taille de l'image doit etre de 730x400 pixels
                    </mdb-alert>
                    <mdb-file-input class="align-items" @getValue="getFileInputValue" :width="730"
                                    :height="400" btnColor="mdb-color"
                                    btn-title="Image de couverture" rounded img
                                    :src="webinaire.currentimageurl" required/>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <h3>Informations Youtube complémentaires</h3>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input label="Stream Key" v-model="webinaire.youtubestreamkey"/>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input label="Stream Server Url" v-model="webinaire.youtubestreamsvrurl"/>
                  </mdb-col>
                </mdb-row>
                <mdb-btn color="orange accent-3" v-if="isSaving===false" v-on:click="saveinfos()">
                  Sauvegarder
                </mdb-btn>
              </mdb-container>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
    <section class="mt-3" v-if="ispast">
      <mdb-row>
        <mdb-col xl="6" md="6" class="mb-4">
          <mdb-btn outline v-on:click="gotowebinarslist" color="primary">
            <mdb-icon icon="arrow-left" class="mr-1"/>
            Retour à la liste des webinaires
          </mdb-btn>
        </mdb-col>
      </mdb-row>
      <mdb-row v-if="errormsg">
        <mdb-col xl="12" md="12" class="mb-4">
          <mdb-alert color="danger">
            {{ errormsg }}
          </mdb-alert>
        </mdb-col>
      </mdb-row>
      <mdb-row v-if="!cantload">
        <mdb-col xl="12" md="12" class="mb-4">
          <mdb-card>
            <mdb-card-header color="orange accent-3">Modifier un Webinaire</mdb-card-header>
            <mdb-card-body>
              <mdb-container>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input label="Date / heure du webinaire" :value="handlePickersValue()"
                               @click.native="$refs.datePicker.open()" required/>
                    <mdb-date-picker-2
                      ref="datePicker"
                      disable-input
                      v-model="webinaire.thedate"
                      disabledPast
                      @close="opentimepicker"/>
                    <mdb-time-picker
                      ref="timePicker"
                      disable-input
                      v-model="webinaire.thehour"/>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input type="textarea"
                               label="Contenu du courriel à envoyer après la présentation"
                               :rows="15" required v-model="webinaire.afterwebinaremailcontent"/>
                <mdb-switch onLabel="Envoyer le courriel"
                            offLabel="Ne pas envoyer le courriel" v-model="webinaire.sendmail"/>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input type="textarea"
                               label="Contenu du courriel à envoyer après la présentation aux non-participants"
                               :rows="15" required v-model="webinaire.afterwebinarnonattendeeemailcontent"/>
                  </mdb-col>
                </mdb-row>
                <mdb-btn color="orange accent-3" v-if="isSaving===false" v-on:click="saveinfos()">
                  Sauvegarder
                </mdb-btn>
              </mdb-container>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>

    <mdb-modal :show="isSaving" info :centered="true">
      <mdb-modal-header :close="false">
        <p class="heading">Sauvegarde en cours...</p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="12" class="pt-3">
            <div class="d-flex justify-content-center">
              <mdb-spinner big multicolor/>
            </div>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
    </mdb-modal>
  </mdb-container>
</template>

<script>
import Vue from 'vue';
import Adminheader from '@/components/Adminheader.vue';

import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbInput,
  mdbDatePicker2,
  mdbTimePicker,
  mdbFileInput,
  mdbAlert,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbSpinner,
  mdbSwitch,
} from 'mdbvue';
import Auth from '../libs/Auth';
import Apicall from '../libs/Apicall';

export default Vue.extend({
  name: 'Dashboard',
  components: {
    Adminheader,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbInput,
    mdbDatePicker2,
    mdbTimePicker,
    mdbFileInput,
    mdbAlert,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbSpinner,
    mdbSwitch,
  },
  data() {
    return {
      webinaire: {
        ident: '',
        title: '',
        thedate: '',
        thehour: '',
        presentedby: '',
        minidesc: '',
        fulldesc: '',
        imageblobbase64: '',
        imageoriginalfilename: '',
        currentimageurl: '',
        youtubeid: '',
        youtubestreamkey: '',
        youtubestreamsvrurl: '',
        afterwebinaremailcontent: '',
        afterwebinaragentemailcontent: '',
        afterwebinarnonattendeeemailcontent: '',
        sendmail: false,
      },
      datePickerValue: '',
      timePickerValue: '',
      isSaving: false,
      errormsg: '',
      youtubevalidateerrmsg: '',
      youtubevalidationisloading: false,
      cantload: false,
      canopentimepicker: false,
      ispast: false,
    };
  },
  mounted() {
    Auth.validateIfCanAccess('ADMINISTRATOR', 'Dashboard');
    const self = this;
    const api = new Apicall();
    this.errormsg = '';
    api.getOneWebinarAdmin(this.$route.params.ident).then((response) => {
      if (response.status === 'OK') {
        console.log(response);
        // if (response.data.webinar.ispast && !response.data.webinar.canshow) {
        //     self.cantload = true;
        //     self.errormsg = 'Vous ne pouvez pas modifier un webinaire qui est déjà passé';
        // }
        if (response.data.webinar.ispast) {
          self.ispast = true;
        }
        self.loaddatainform(response.data.webinar);
      } else {
        self.cantload = true;
        self.errormsg = response.msg;
      }
      self.isSaving = false;
    });
    window.scrollTo(0, 0);
  },
  methods: {
    test() {
      console.log('test');
    },
    loaddatainform(webinar) {
      this.webinaire.ident = webinar.ident;
      this.webinaire.title = webinar.title;
      this.webinaire.thedate = webinar.thedate;
      this.webinaire.thehour = webinar.thehour;
      this.webinaire.presentedby = webinar.presentedby;
      this.webinaire.minidesc = webinar.minidesc;
      this.webinaire.fulldesc = webinar.fulldesc;
      this.webinaire.currentimageurl = webinar.currentimageurl;
      this.webinaire.youtubeid = webinar.youtubeid;
      this.webinaire.youtubestreamkey = webinar.youtubestreamkey;
      this.webinaire.youtubestreamsvrurl = webinar.youtubestreamsvrurl;
      this.webinaire.afterwebinaremailcontent = webinar.afterwebinaremailcontent;
      this.webinaire.afterwebinaragentemailcontent = webinar.afterwebinaragentemailcontent;
      this.webinaire.afterwebinarnonattendeeemailcontent = webinar.afterwebinarnonattendeeemailcontent;
      this.webinaire.sendmail = webinar.sendmail;

      this.datePickerValue = webinar.thedate;
      this.timePickerValue = webinar.thehour;
      window.setTimeout(() => {
        this.canopentimepicker = true;
      }, 1000); // pour empecher l'ouverture sans que ça soit spécifiquement voulu
    },
    opentimepicker() {
      if (this.canopentimepicker) {
        this.$refs.timePicker.open();
      }
    },
    gotowebinarslist() {
      this.$router.push({ name: 'Webinairelist' });
    },
    handlePickersValue() {
      let output = '';
      if (this.webinaire.thedate && this.webinaire.thehour) output = `${this.webinaire.thedate}, ${this.webinaire.thehour}`;
      else if (this.webinaire.thedate) output = this.webinaire.thedate;
      else if (this.webinaire.thehour) output = this.webinaire.thehour;
      return output;
    },
    getFileInputValue(val) {
      const reader = new FileReader();
      const thefile = val[0];
      const self = this;
      reader.onload = (() => {
        self.webinaire.imageblobbase64 = btoa(reader.result);
      });
      reader.readAsBinaryString(thefile);
      this.webinaire.imageoriginalfilename = thefile.name;
    },
    saveinfos() {
      this.isSaving = true;
      const self = this;
      const api = new Apicall();
      this.errormsg = '';
      api.editWebinaire(this.webinaire).then((response) => {
        if (response.status === 'OK') {
          self.$router.push({ name: 'Webinairelist' });
        } else {
          self.errormsg = response.msg;
        }
        self.isSaving = false;
      });
      window.scrollTo(0, 0);
    },
    validateYoutubeId() {
      this.youtubevalidationisloading = true;
      const self = this;
      const api = new Apicall();
      api.validateYoutubeId(this.webinaire.youtubeid).then((response) => {
        self.youtubevalidationisloading = false;
        if (response.status === 'OK') {
          self.youtubevalidateerrmsg = false;
          self.webinaire.title = response.data.yt.title;
          self.webinaire.thehour = response.data.yt.scheduledStartTimeTime;
          self.webinaire.thedate = response.data.yt.scheduledStartTimeDate;
          self.webinaire.fulldesc = response.data.yt.description;
        } else {
          self.youtubevalidateerrmsg = response.msg;
        }
      });
    },
  },
});
</script>

<style scoped>
.img-placeholder {
  align-items: unset !important;
}
</style>
